<template>
  <div
    ref="autocomplete"
    class="c-service-menu__search-suggestions"
    :style="{
      'max-height': height,
      'transform': `translateX(${offset}px)`
    }"
  >
    <div class="u-text-align--center">
      <Loader
        v-if="loading"
        size="large"
        color="medium-gray"
        class="u-mt--m u-flex u-ml--a u-mr--a"
      />
    </div>
    <ul
      v-if="!loading"
      class="u-bare-list"
    >
      <li
        v-for="(suggestion, index) in suggestions"
        :key="index"
      >
        <a
          :href="suggestion.url"
          class="c-service-menu__search-suggestion u-flex u-flex-direction-column"
          :class="{
            'is-active': activeIndex == index
          }"
          @click="trackSuggestionClick(suggestion.title)"
        >
          <span
            class="u-text-ellipsis u-font-size-large"
            v-html="suggestion.title"
          />
          <span class="u-text-dark-gray u-font-size-small">{{ suggestion.tag }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { disablePageScroll, enablePageScroll } from 'scroll-lock'
import Loader from '@/CVI/WebCore/components/Loader.vue'
import tracker from '@/CVI/WebCore/core-tracker'

let heightSample

export default {
  components: {
    Loader
  },
  props: {
    suggestions: {
      type: Array,
      default: () => []
    },
    offset: {
      type: Number,
      default: 0
    },
    activeIndex: {
      type: Number,
      default: -1
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      height: ''
    }
  },
  watch: {
    suggestions() {
      if (this.suggestions.length && this.$mq != 'desktop') {
        disablePageScroll(this.$refs.autocomplete)
        this.$nextTick(() => {
          if (heightSample) {
            heightSample.remove()
          }
          heightSample = document.createElement('div')
          heightSample.style = 'position: fixed; height: 100%; left: 100%;top: 0;'
          document.body.appendChild(heightSample)
          this.height = `${heightSample.offsetHeight - 60}px`
        })
      } else {
        enablePageScroll(this.$refs.autocomplete)
        this.height = ''
        if (heightSample) {
          heightSample.remove()
          heightSample = null
        }
      }
    }
  },
  methods: {
    trackSuggestionClick(suggestion) {
      tracker.trackQuickSearchSuggestClick({
        event: 'menuNavigationSearchDropdown',
        dropdownSearchClick: suggestion
      })
    }
  }
}

</script>
